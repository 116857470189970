import Board, { moveCard, removeCard, addCard } from '@asseinfo/react-kanban'
import "@asseinfo/react-kanban/dist/styles.css";
import "./Board.css";
import Button from 'react-bootstrap/Button';
import { protectedResources } from "../../authConfig";
import useBoard from './store/Board';
import { ArrowUpIcon, ArrowDownIcon, Bars2Icon, PlusIcon, ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, BugAntIcon } from "@heroicons/react/24/solid";
import AddTaskModal from './AddTaskModal/AddTaskModal';
import TicketsDetail from './TicketDetails/TicketDetails';
import React, { useState, useEffect, useRef } from 'react';
import { customBoardData } from "./store/data";
import { timeDifference } from '../../utils/frontUtils';
import { GET_TICKETS_PATH, BACKEND_URL, ESTADOS_TICKETS, PRIORIDADES_TICKETS, TIPOS_TICKETS } from '../../constants';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useParams } from "react-router-dom";

const BoardPage = () => {
  const { id } = useParams();
  const [updatePage, setUpdatePage] = useState(true);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateTicket, setUpdateTicket] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const { board, setBoard } = useBoard();
  const [searchTerm, setSearchTerm] = useState(null);
  const [filterResults, setFilterResults] = useState(null);
  const [filterByUser, setFilterByUser] = useState(null);
  const [verRecientes, setVerRecientes] = useState(false);

  const [varianteFede, setVarianteFede] = useState("light");
  const [varianteDama, setVarianteDama] = useState("light");
  const [varianteMaxi, setVarianteMaxi] = useState("light");
  const [varianteMeli, setVarianteMeli] = useState("light");
  const [varianteCharly, setVarianteCharly] = useState("light");
  const [varianteAlejo, setVarianteAlejo] = useState("light");

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  const setVariantDefault = () => {
    setVarianteFede("light");
    setVarianteDama("light");
    setVarianteCharly("light");
    setVarianteMeli("light");
    setVarianteMaxi("light");
    setVarianteAlejo("light");
  }

  const onUserFilterChange = (event) => {
    let valor = event.target.value;
    if ("Fede" == valor) {
      setVariantDefault();
      if(varianteFede == "primary"){
        setVarianteFede("light");
        setFilterByUser(null);
      } else {
        setVarianteFede("primary");
        setFilterByUser('Fede');
      }
    } else if ("Dama" == valor) {
      setVariantDefault();
      if(varianteDama == "primary"){
        setVarianteDama("light");
        setFilterByUser(null);
      } else {
        setVarianteDama("primary");
        setFilterByUser('Dama');
      }
    } else if ("Charly" == valor) {
      setVariantDefault();
      if(varianteCharly == "primary"){
        setVarianteCharly("light");
        setFilterByUser(null);
      } else {
        setVarianteCharly("primary");
        setFilterByUser('Charly');
      }
    } else if ("Meli" == valor) {
      setVariantDefault();
      if(varianteMeli == "primary"){
        setVarianteMeli("light");
        setFilterByUser(null);
      } else {
        setVarianteMeli("primary");
        setFilterByUser('Meli');
      }
    } else if ("Maxi" == valor) {
      setVariantDefault();
      if(varianteMaxi == "primary"){
        setVarianteMaxi("light");
        setFilterByUser(null);
      } else {
        setVarianteMaxi("primary");
        setFilterByUser('Maxi');
      }
    } else if ("Alejo" == valor) {
      setVariantDefault();
      if(varianteAlejo == "primary"){
        setVarianteAlejo("light");
        setFilterByUser(null);
      } else {
        setVarianteAlejo("primary");
        setFilterByUser('Alejo');
      }
    }
  }

  const handleCardMove = (_card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
  }

  const getColumn = (card) => {
    const column = board.columns.filter((column) => column.cards.includes(card))
    return column[0]
  }

  const addAssetModalSetting = () => {
    //let encontrado = assets.find(x => x._id === selectedAssetDataId);
    setShowAssetModal(!showAssetModal);
  };

  const editTicketModalSetting = (props) => {
    if (props) setUpdateTicket(allTickets.find(x => x._id === props._id))
    // console.log("las props: " + JSON.stringify(props)); //las props: {"id":9,"title":"Data Table Page","description":"Server side Pagination"}
    setShowEditModal(!showEditModal);
  };

  const clickEnCardProps = (props) => {

  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
    console.log("OK hibrido");
  };

  // Fetching Data of All Assets
  const fetchTicketsData = () => {
    execute("GET", `${BACKEND_URL}${GET_TICKETS_PATH}`).then((response) => {
      setAllTickets(response);

      if (id) {
        setUpdateTicket(response.find(x => x['id_generado'] == id))
        setShowEditModal(!showEditModal);
      }
    })
  }

  useEffect(() => {
    if (allTickets) {
      let abiertos = [];
      let enProgreso = [];
      let pendientes = [];
      let bloqueados = [];
      let solucionados = [];
      let finalizados = [];

      if (!searchTerm && !filterByUser && !verRecientes) {
        setFilterResults(allTickets);
        allTickets.map((ticket) => {
          if (ticket.estado == ESTADOS_TICKETS.abierto)
            abiertos.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.enProgreso)
            enProgreso.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.pendiente)
            pendientes.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.bloqueado)
            bloqueados.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.solucionado)
            solucionados.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.finalizado)
            finalizados.push(ticket);
        })
      } else {
        filterResults.map((ticket) => {
          if (ticket.estado == ESTADOS_TICKETS.abierto)
            abiertos.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.enProgreso)
            enProgreso.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.pendiente)
            pendientes.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.bloqueado)
            bloqueados.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.solucionado)
            solucionados.push(ticket);
          else if (ticket.estado == ESTADOS_TICKETS.finalizado)
            finalizados.push(ticket);
        })
      }

      setBoard(customBoardData(abiertos, enProgreso, pendientes, bloqueados, solucionados, finalizados));
    }
  }, [allTickets, filterResults]);


  useEffect(() => {
    //TODO por aca mandar el
    fetchTicketsData();
  }, [execute, updatePage]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const recientemente = (event) => {
    setVerRecientes(!verRecientes);
  };

  useEffect(() => {
    let results;
    setFilterResults(allTickets);
    if (searchTerm) {
      results = allTickets.filter((ticket) =>
        ticket.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilterResults(results);
    } 
    
    if (filterByUser) {
      switch (filterByUser) {
        case 'Fede':
          if (results)
            results = Object.values(results).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("federico beron"));
          else
            results = Object.values(allTickets).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("federico beron"));
            break;
        case 'Dama':
          if (results)
            results = Object.values(results).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("damariz albornoz"));
          else
            results = Object.values(allTickets).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("damariz albornoz"));
            break;
        case 'Maxi':
          if (results)
            results = Object.values(results).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("maximiliano moreira"));
          else
            results = Object.values(allTickets).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("maximiliano moreira"));
            break;
        case 'Meli':
          if (results)
            results = Object.values(results).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("melisa aranda"));
          else
            results = Object.values(allTickets).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("melisa aranda"));
              break;
        case 'Charly':
          if (results)
            results = Object.values(results).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("carlos quintero"));
          else
            results = Object.values(allTickets).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("carlos quintero"));
            break;
        case 'Alejo':
          if (results)
            results = Object.values(results).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("alejo david"));
          else
            results = Object.values(allTickets).filter((ticket) =>
              ticket.asignadoA.toLowerCase().includes("alejo david"));
            break;
        default:
          break;
      }

      setFilterResults(results);
    } 

    if(verRecientes) {
      if(!results) {
        
        let today = new Date();
        let lastTwoDay = new Date(today.getTime() - (4 * 24 * 60 * 60 * 1000));

        results = Object.values(allTickets).map(a => {
          let a_date = new Date(a['updatedAt']);

          if (a_date.getTime() > lastTwoDay.getTime()){
            console.log("ESTE SI: " + JSON.stringify(a));
            return a;
          }
        });
      }else
          results = results.sort(function(a, b){return b.updatedAt - a.updatedAt})[0];
      const thisIsLife = Object.values(results).filter(element => element != null)
      setFilterResults(thisIsLife);
    }
    //results? setFilterResults(results) : setFilterResults(allTickets);
  }, [searchTerm, filterByUser, verRecientes]);

  return (
    <div className="board-container grid grid-cols-1 col-span-12 lg:col-span-10">
      <span>Tickets Dashboard</span>
      <input
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        className="block p-2.5 w-full bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
        onChange={handleChange}
      />

      {/* ------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------ */}
      <div className='flex-wrap flex-row'>
        <Button as="input" type="Input" value="Fede" className='mr-4 mt-2' variant={varianteFede} onClick={onUserFilterChange} />
        <Button as="input" type="Input" value="Dama" className='mr-4 mt-2' variant={varianteDama} onClick={onUserFilterChange} />
        <Button as="input" type="Input" value="Maxi" className='mr-4 mt-2' variant={varianteMaxi} onClick={onUserFilterChange} />
        <Button as="input" type="Input" value="Meli" className='mr-4 mt-2' variant={varianteMeli} onClick={onUserFilterChange} />
        <Button as="input" type="Input" value="Charly" className='mr-4 mt-2' variant={varianteCharly} onClick={onUserFilterChange} />
        <Button as="input" type="Input" value="Alejo" className='mr-4 mt-2' variant={varianteAlejo} onClick={onUserFilterChange} />
      </div>

      <div className='flex-wrap'>
      <a className='mx-4 no-underline cursor-pointer' onClick={recientemente}>Actualizados recientemente</a>
      <a className='mr-4 no-underline cursor-pointer' onClick={recientemente}>Limpiar</a>

        <button
          type="button"
          className="mt-3 rounded-md px-3 py-2 text-sm font-semibold text-gray-100 bg-blue-900 hover:bg-gray-50"
          onClick={() => addAssetModalSetting()}
        >
          Crear Ticket
        </button>

      </div>
      
      {/* ------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------ */}

      <Board
      disableColumnDrag
      disableCardDrag
        renderCard={(props) => (
          <div className='kanban-card bg-gray-100 cursor-pointer' 
          onClick={() => { return editTicketModalSetting(props) }}>
            
            <div style={{ fontSize: "12px" }}>
              <p className="italic font-light text-gray-900 mb-0">{timeDifference(Date.parse(props.createdAt), "Creado")}</p>
              {/*<p className="italic text-sm font-light text-gray-900">{timeDifference(Date.parse(props.updatedAt), "Actualizado")}</p>*/}
            </div>
              <span className='mt-0'>
                {props.title}
              </span>

            <span style={{ fontSize: "15px" }}>{props.description}</span>

            <div className='flex justify-between'>
              <div className='flex'>
                <div className='inline-block'>
                  {props.prioridad == PRIORIDADES_TICKETS.baja ?
                    <ArrowDownIcon className="inline mr-2 h-6 w-6 text-green-700" aria-hidden="true" /> :
                    props.prioridad == PRIORIDADES_TICKETS.media ?
                      <Bars2Icon className="inline mr-2 h-6 w-6 text-orange-400" aria-hidden="true" /> :
                      <ArrowUpIcon className="mr-2 h-6 w-6 text-red-800 inline" aria-hidden="true" />}
                </div>
                <div className='inline-block'>

                  {props.tipo == TIPOS_TICKETS.issue ?
                    <ClipboardDocumentCheckIcon className="inline mr-2 h-6 w-6 text-blue-800" aria-hidden="true" /> :
                    props.tipo == TIPOS_TICKETS.tarea ?
                      <ClipboardDocumentListIcon className="inline mr-2 h-6 w-6 text-green-800" aria-hidden="true" /> :
                      <BugAntIcon className="inline mr-2 h-6 w-6 text-red-800" aria-hidden="true" />}

                </div>
              </div>

              <div className='flex'>

                <div
                  className="h-8 w-8 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 flex align-center justify-center"
                  alt="profile" >
                  <p className="pt-1">{props.asignadoA ? props.asignadoA.split(" ").map((n) => n[0]).join("") : ""}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        renderColumnHeader={(props) => {
          return (
            <div className='column-header'>
              <span>{props.title}</span>
              <PlusIcon
                className="h-6 w-6 text-blue-950"
                aria-hidden="true"
                onClick={addAssetModalSetting}
              />
            </div>
          )
        }}
      >
        {board}
      </Board>

      {showAssetModal && (
        <AddTaskModal
          addAssetModalSetting={addAssetModalSetting}
          handlePageUpdate={handlePageUpdate}
        />
      )}

      {showEditModal && (
        <TicketsDetail
          addAssetModalSetting={editTicketModalSetting}
          handlePageUpdate={handlePageUpdate}
          updateTicketData={updateTicket}
          showEditModal ={showEditModal}
          setShowEditModal = {setShowEditModal}
        />
      )}
    </div>
  )
}

export default BoardPage
