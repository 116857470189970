import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { Bars2Icon } from "@heroicons/react/24/solid";
import { BugAntIcon } from "@heroicons/react/24/solid";
import { protectedResources } from "../../../authConfig";
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import { useMsal } from '@azure/msal-react';
import Multiselect from 'multiselect-react-dropdown';
import Select, { components } from "react-select";
import { createClaimsTable } from '../../../utils/claimUtils';
import { ADD_COMMENTS_PATH, GET_COMENTARIOS, GET_ADJUNTOS, GET_OPCIONES_BLOQUEADO_POR, DEFAULT_TICKET, BACKEND_URL, PRIORIDADES_TICKETS, TIPOS_TICKETS, UPDATE_TICKETS_PATH, ESTADOS_TICKETS, COLORES_ESTADOS_TICKETS } from '../../../constants';
import { timeDifference } from '../../../utils/frontUtils';
import { getEstadosDisponibles } from '../../../utils/EstadosTicketUtils';
import { Tab } from '@headlessui/react';
import HistoryTable from "../../../components/HistoryTable";
import chroma from 'chroma-js';
import CommentsComponent from './CommentsComponent';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import AccessAlarm from '@mui/icons-material/AccessAlarm';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InputFile } from '../AddTaskModal/UploadFileComponent/InputFile';
import CompletarCampoModal from './CompletarCampoModal';
import AgregarComentarioModal from './AgregarComentarioModal';
import AgregarSolucionModal from './AgregarSolucionModal';

export default function TicketDetails({ addAssetModalSetting, handlePageUpdate, updateTicketData = null, setShowEditModal= null, showEditModal = true }) {
  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  const getLogginInfo = () => {
    const tokenClaims = createClaimsTable(activeAccount.idTokenClaims);
    let email = "";
    let name = "";
    let id = "";

    Object.keys(tokenClaims).map((key) => {
      if (tokenClaims[key][0] == "name") {
        name = tokenClaims[key][1];
      } else if (tokenClaims[key][0] == "oid") {
        id = tokenClaims[key][1];
      } else if (tokenClaims[key][0] == "email") {
        email = tokenClaims[key][1];
      }
    });

    return { 'currentEmail': email, 'currentName': name, 'currentId': id };
  }

  const tipitos = [
    { value: TIPOS_TICKETS.issue, label: TIPOS_TICKETS.issue, color: "#17469e", icon: <ClipboardDocumentCheckIcon className="inline mr-2 h-6 w-6 text-blue-800" aria-hidden="true" /> },
    { value: TIPOS_TICKETS.tarea, label: TIPOS_TICKETS.tarea, color: "#b72842", icon: <ClipboardDocumentListIcon className="inline mr-2 h-6 w-6 text-green-800" aria-hidden="true" /> },
    { value: TIPOS_TICKETS.bug, label: TIPOS_TICKETS.bug, color: "#b72842", icon: <BugAntIcon className="inline mr-2 h-6 w-6 text-red-800" aria-hidden="true" /> }
  ];

  const prioridades = [
    { value: PRIORIDADES_TICKETS.baja, label: PRIORIDADES_TICKETS.baja, color: "#17469e", icon: <ArrowDownIcon className="inline mr-2 h-6 w-6 text-green-700" aria-hidden="true" /> },
    { value: PRIORIDADES_TICKETS.media, label: PRIORIDADES_TICKETS.media, color: "#b72842", icon: <Bars2Icon className="inline mr-2 h-6 w-6 text-orange-400" aria-hidden="true" /> },
    { value: PRIORIDADES_TICKETS.alta, label: PRIORIDADES_TICKETS.alta, color: "#b72842", icon: <ArrowUpIcon className="mr-2 h-6 w-6 text-red-800 inline" aria-hidden="true" /> }
  ];

  const [ticket, setTicket] = useState(updateTicketData ? updateTicketData : DEFAULT_TICKET);
  const estadoOriginal = updateTicketData ? updateTicketData.estado : DEFAULT_TICKET.estado;

  const [selectedfile, SetSelectedFile] = useState([]);
  const [opcionesBloqueadoPor, setOpcionesBloqueadoPor] = useState([]);
  const [bloqueadoPor, setBloqueadoPor] = useState([]);
  const cancelButtonRef = useRef(null);
  const [estados, setEstados] = useState(null);
  const [observadores, setObservadores] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [options, setOptions] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [ticketHistory, setTicketHistory] = useState(null);
  const [showBloqueadoPorError, setShowBloqueadoPorError] = useState(false);
  const [showComentarioPendiente, setShowComentarioPendiente] = useState(false);
  const [showModalSolucionado, setShowModalSolucionado] = useState(false);
  
  let [categories] = useState({
    Comentarios: [],
    Historial: [],
  })

  const [stringCreado, setStringCreado] = useState("");
  const [stringActualizado, setStringActualizado] = useState("");

  // Fetching Data of All Assets
  const setCurrentUpdateDate = () => {
    let copy_asset = ticket;
    const dateObj = new Date();

    copy_asset['fechaActualizacion'] = dateObj.toLocaleString();
    setTicket({ ...ticket, ['fechaActualizacion']: dateObj.toLocaleString() });
  }

  const fetchUsersData = () => {
    execute("GET", `${BACKEND_URL}api/users/getAllUsers`).then((response) => {
      // si no hay usuarios guardados en la base entonces lo recupero de forma remota
      if (response && response.length > 10) {
        setUsuarios(response);

        //setear los observadores
        let observadores_ = [];
        let encontrado = null;
        ticket.observadores.forEach((item) => {
          encontrado = response.find((x) => { return (x.id == item) });
          if (encontrado) observadores_.push({ name: encontrado['displayName'], id: encontrado['id'] });
        });
        setObservadores(observadores_);
      }
    })
  }

  const fetchTicketHistory = () => {
    if (ticket){
      execute("GET", `${BACKEND_URL}api/asset/getAllHistory/${ticket._id}`).then((response) => {
        if (response) {
          setTicketHistory(response);}
      });
    }
  };

  const setInformador = () => {
    let { currentEmail, currentName, currentId } = getLogginInfo();
    let copy_asset = ticket;
    copy_asset['informador'] = currentName;
    copy_asset['idInformador'] = currentId;
    setTicket(copy_asset);
    setTicket({ ...ticket, ['informador']: currentName });
    setTicket({ ...ticket, ['idInformador']: currentId });
  }

  function search(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].tipo === nameKey) {
        return myArray[i];
      }
    }
  }

  const fetchComentarios = () => {
    execute("GET", `${BACKEND_URL}${GET_COMENTARIOS}${ticket.id_generado}`).then((response) => {

      if (response && response.length > 0) {
        setComentarios(response);
      }
    });
  }

  const fetchOpcionesBloqueado = () => {
    execute("GET", `${BACKEND_URL}${GET_OPCIONES_BLOQUEADO_POR}`).then((response) => {
      // si no hay usuarios guardados en la base entonces lo recupero de forma remota
      if (response && response.length > 0) {
        let newArray = response.map((user) => { if (ticket._id != user._id) return ({ name: user['title'], id: user['_id'] }) });
        const whitOutNulls = newArray.filter(element => element != null);
        //console.log("whitOutNulls: " + JSON.stringify(whitOutNulls));
        setOpcionesBloqueadoPor(whitOutNulls);

        let bloqueados_ = [];
        let encontrado = null;

        ticket.bloqueadoPor.forEach((item) => {
          encontrado = whitOutNulls.find((x) => { return (x.id == item) });
          if (encontrado) bloqueados_.push({ name: encontrado['name'], id: encontrado['id'] });
        });

        //bloqueados_ = bloqueados_.filter(element => element != null);
        setBloqueadoPor(bloqueados_);
      }
    })
  }

  const fetchAdjuntos = () => {
    if (updateTicketData['adjuntos'][0] )
      execute("GET", `${BACKEND_URL}${GET_ADJUNTOS}${updateTicketData.adjuntos}`).then((response) => {
        // si no hay usuarios guardados en la base entonces lo recupero de forma remota
        if (response && response.length > 0)
          SetSelectedFile(response);
      })
  }

  const handleTiemposChange = (key, value) => {
    setTicket({ ...ticket, [key]: value });
  }

  const handleInputChange = (key, value) => {
    if (value['name']) {
      if (value['name'] == 'tipo') {
        setTicket({ ...ticket, ['tipo']: key['label'] });
      } else if (value['name'] == 'estado') {
        setTicket({ ...ticket, ['estado']: key['label'] });
      } else {
        setTicket({ ...ticket, ['prioridad']: key['label'] });
      }
    } else
      setTicket({ ...ticket, [key]: value });
  };

  useEffect(() => {
    fetchUsersData();
    fetchOpcionesBloqueado();
    setStringCreado(timeDifference(Date.parse(ticket.createdAt), "Creado"));
    setStringActualizado(timeDifference(Date.parse(ticket.updatedAt), "Actualizado"));
    fetchComentarios();
    fetchTicketHistory();

    fetchAdjuntos();
  }, [execute]);

  useEffect(() => {
    let newArray = usuarios.map((user) => { return ({ name: user.displayName, id: user.id }) });
    setOptions(newArray);

  }, [usuarios]);

  //Actualiza los estados destino disponibles
  useEffect(() => {
    if (ticket) {
      let estados_ = getEstadosDisponibles(ticket.estado).map((x) => { return ({ value: x, label: x, color: COLORES_ESTADOS_TICKETS[x], icon: '' }) });
      setEstados(estados_);
    }
  }, [estadoOriginal]);

  const handleAsignadoAChange = (nombre, id) => {
    let copy_asset = ticket;
    copy_asset['asignadoA'] = nombre;
    copy_asset['idAsignadoA'] = id;
    setTicket(copy_asset);
    setTicket({ ...ticket, ['asignadoA']: nombre });
  };

  // lsita entera, el que acabo de agregar
  const handleObservadoresChange = (selectedList, selectedItem) => {
    setObservadores(selectedList);
  };

  // lista de posibles bloqueadores
  const handleBloqueadoPorChange = (selectedList, selectedItem) => {
    setBloqueadoPor(selectedList);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data.icon}
      {children}
    </components.SingleValue>
  );

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      {props.data.icon}
      {props.data.label}
    </components.Option>
  );

  // manda a actualizar el ticket
  const editTicket = () => {
    let copy_asset = ticket;
    // agrego info del usuario para el historico    
    
    let { currentEmail, currentName, currentId } = getLogginInfo();

    copy_asset['usuario'] = activeAccount["name"];
    copy_asset['usuario_email'] = currentEmail;
    copy_asset['usuario_id'] = currentId;

    if (observadores)
      copy_asset['observadores'] = observadores.map((observador) => { return (observador['id']) });

    if (bloqueadoPor) {
      copy_asset['bloqueadoPor'] = bloqueadoPor.map((bloqueador) => { return (bloqueador['id']) });
      //console.log("---------bloqueadoPor: " + JSON.stringify(copy_asset['bloqueadoPor']))
    }

    let sin_errores = true;
    
    //TODO por aca voy, seguir con los cases de los cambios de estado
    if (estadoOriginal != copy_asset.estado) {
      switch (copy_asset.estado) {
        case ESTADOS_TICKETS.bloqueado:
          sin_errores = pasoABloqueado();
          break;
        case ESTADOS_TICKETS.pendiente:
          sin_errores = pasoAPendiente();
          break;
        case ESTADOS_TICKETS.solucionado:
          sin_errores = pasoASolucionado();
          break;
        case ESTADOS_TICKETS.abierto:
          sin_errores = pasoAAbierto();
          break;
        case ESTADOS_TICKETS.finalizado:
          sin_errores = pasoAFinalizado();
          break;
        default:
          break;
      }
    }

    if (sin_errores)
    execute("POST", `${BACKEND_URL}${UPDATE_TICKETS_PATH}`, copy_asset).then((r) => {
      if (r) {
        addAssetModalSetting();
        handlePageUpdate();
        //console.log("Ticket actualizado correctamente: " + JSON.stringify(r));
      }
    })
  }

  const pasoAFinalizado = () => {
    if(ticket.solucion && ticket.solucion.length > 10) 
      return true;
    setShowModalSolucionado(true);
    return false;
  }

  const pasoASolucionado = () => {
    
    setShowModalSolucionado(true);
    return false;
  }

  const pasoAAbierto = () => {    
    let copy_asset = ticket;
    copy_asset['bloqueadoPor'] = [];
    setTicket(copy_asset);
    setTicket({ ...ticket, ['bloqueadoPor']: [] });
    return true;
  }

  const completoSolucionado = (solucion) => {
    const dateObj = new Date();
    let copy_asset = ticket;
    copy_asset['fechaResolucion'] = dateObj.toLocaleString();
    copy_asset['solucion'] = solucion;
    setTicket(copy_asset);
    setTicket({ ...ticket, ['fechaResolucion']: dateObj.toLocaleString() });
    setTicket({ ...ticket, ['solucion']: solucion });
    
    execute("POST", `${BACKEND_URL}${UPDATE_TICKETS_PATH}`, copy_asset).then((r) => {
      if (r) {
        setShowModalSolucionado(false);
        addAssetModalSetting();
        handlePageUpdate();
        //console.log("Ticket actualizado correctamente: " + JSON.stringify(r));
      }
    })
    
  }

  const pasoABloqueado = () => {
    if (ticket['bloqueadoPor'] && ticket['bloqueadoPor'][0] ) {
      setShowBloqueadoPorError(false);
    }else {
      setShowBloqueadoPorError(true);
      return false;
    }
    return true;
  }

  const pasoAPendiente = () => {
    setShowComentarioPendiente(true);
    return false;
  }

  const razonAPendiente = (comment) => {
    let { currentEmail, currentName, currentId } = getLogginInfo();
    let nombre_separado = currentName.split(" ");

    let comentario = {
      ticket_id: ticket._id,
      userId: currentId,
      comId: currentEmail,
      fullName: currentName,
      userProfile: `https://ui-avatars.com/api/?name=${nombre_separado[0]}+${nombre_separado[1]}`,
      text: comment,
      avatarUrl: `https://ui-avatars.com/api/?name=${nombre_separado[0]}+${nombre_separado[1]}&background=063970&color=fff`,
      replies: []
    }

    // deje que se le asigne al usuario que lo creo
    let copy_asset = ticket;
    copy_asset['asignadoA'] = copy_asset['informador'];
    copy_asset['idAsignadoA'] = copy_asset['idInformador'];
    setTicket(copy_asset);
    setTicket({ ...ticket, ['asignadoA']: copy_asset['informador'] });
    setTicket({ ...ticket, ['idAsignadoA']: copy_asset['idInformador'] });
    
    execute("POST", `${BACKEND_URL}${ADD_COMMENTS_PATH}`, comentario).then((r) => {
      if (r) {
        console.log("Comentario creado correctamente: " + JSON.stringify(r));

        setShowComentarioPendiente(false);
        execute("POST", `${BACKEND_URL}${UPDATE_TICKETS_PATH}`, copy_asset).then((r) => {
          if (r) {
            addAssetModalSetting();
            handlePageUpdate();
            setShowComentarioPendiente(false);
            //console.log("Ticket actualizado correctamente: " + JSON.stringify(r));
          }
        })

      }
    })
  }
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Transition.Root show={showEditModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShowEditModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={"relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 max-h-full " + (updateTicketData.adjuntos && Object.values(updateTicketData.adjuntos)[0]? "w-full" : "sm:w-full sm:max-w-3xl")}>

                <div className="mt-4 text-left sm:mt-0 sm:ml-4 sm:text-left ">

                  {/* Titulo */}
                  <div className="mx-auto w-full max-w-[550px] sm:flex sm:flex-row justify-between">
                    <Dialog.Title
                      as="p"
                      className="italic text-sm font-light text-gray-900"
                    >
                      {stringCreado}
                    </Dialog.Title>
                    <Dialog.Title
                      as="p"
                      className="italic text-sm font-light text-gray-900"
                    >
                      {stringActualizado}
                    </Dialog.Title>
                  </div>

                  <Dialog.Title
                    as="h3"
                    className="mx-auto w-full max-w-[550px] text-lg font-semibold leading-6 text-gray-900 mb-4"
                  >
                    Editar {ticket.id_generado}
                  </Dialog.Title>

                  {/* Inputs */}
                  <form className="group" noValidate>

                    <div className="mx-auto w-full max-w-[550px] bg-white">

                      {/* Estado */}
                      <div className="mb-4">
                        <label
                          htmlFor="estado"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Estado</span>
                          {estados &&
                            <Select
                              name="estado"
                              id="estado"
                              value={estados.find(x => x.value == ticket.estado)}
                              options={estados}
                              onChange={handleInputChange}
                              styles={{
                                control: (styles) => ({ ...styles, color: 'red', backgroundColor: estados.find(x => x.value == ticket.estado).color }),
                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                  const color = chroma(data.color);
                                  return {
                                    ...styles,
                                    backgroundColor: isDisabled
                                      ? undefined
                                      : isSelected
                                        ? data.color
                                        : isFocused
                                          ? color.alpha(0.1).css()
                                          : undefined,
                                    color: isDisabled
                                      ? '#ccc'
                                      : isSelected
                                        ? chroma.contrast(color, 'white') > 2
                                          ? 'white'
                                          : 'black'
                                        : data.color,
                                    cursor: isDisabled ? 'not-allowed' : 'default',

                                    ':active': {
                                      ...styles[':active'],
                                      backgroundColor: !isDisabled
                                        ? isSelected
                                          ? data.color
                                          : color.alpha(0.3).css()
                                        : undefined,
                                    },
                                  };
                                },
                                input: (styles) => ({ ...styles }),
                                placeholder: (styles) => ({ ...styles }),
                                singleValue: (styles, { data }) => ({ ...styles, color: 'white' }),
                              }}
                              components={{
                                Option,
                                SingleValue
                              }}
                            />
                          }
                        </label>
                      </div>

                      {/* Prioridad */}
                      <div className="mb-4">
                        <label
                          htmlFor="prioridad"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Prioridad</span>

                          <Select
                            name="prioridad"
                            id="prioridad"
                            value={prioridades.find(x => x.value == ticket.prioridad)}
                            options={prioridades}
                            onChange={handleInputChange}
                            styles={{
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center"
                              })
                            }}
                            components={{
                              Option,
                              SingleValue
                            }}
                          />

                        </label>
                      </div>

                      {/* Titulo */}
                      <div className="mb-4">
                        <label
                          htmlFor="title"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Titulo </span>
                          <input
                            type="text"
                            id="title"
                            name="title"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.title}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>
                      </div>

                      {/* Descripcion */}
                      <div className="mb-2 min-h-80">
                        <label
                          htmlFor="description"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Descripcion</span>
                          <textarea
                            id="description"
                            name="description"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.description}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            rows={12} cols={40}
                            required
                          />
                        </label>
                      </div>
                      
                      {/* Adjuntos */}
                      <div className="mb-4">
                        <label
                          htmlFor="adjuntos"
                          className="block text-sm font-medium text-gray-900"
                        >
                          <span>Adjuntos</span><br />
                          <InputFile selectedfile={selectedfile} SetSelectedFile={SetSelectedFile} disabled_={true} />
                        </label>
                      </div>

                      {/* Informador */}
                      <div className="mb-4">
                        <label
                          htmlFor="informador "
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Informador</span>
                          <input
                            type="informador"
                            id="informador"
                            name="informador"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.creadoPor}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            disabled
                          />
                        </label>
                      </div>

                      {/* AsignadoA */}
                      <div className="mb-4">
                        <label
                          htmlFor="asignado"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Asignado a</span>
                          <select
                            name="asignadoA"
                            id="asignadoA"
                            value={ticket['idAsignadoA']}
                            onChange={(e) =>
                              handleAsignadoAChange(e.target.options[e.target.selectedIndex].text, e.target.value)
                            }
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                          >
                            <option disabled selected value> -- Elije una opcion -- </option>
                            {usuarios && usuarios.map((element, index) => {
                              return (
                                <option key={element.id} value={element.id}>{element.displayName}</option>
                              )
                            })}
                          </select>

                        </label>
                      </div>

                      {/* Observadores */}
                      <div className="mb-4">
                        <label
                          htmlFor="observadores"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Observadores</span>

                          <Multiselect
                            name="observadores"
                            id="observadores"
                            selectionLimit="8"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            closeOnSelect="true"
                            placeholder="Observadores.."
                            options={options} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            onSelect={handleObservadoresChange}
                            onRemove={handleObservadoresChange}
                            selectedValues={observadores}
                          />
                        </label>
                      </div>

                      {/* Bloqueado Por */}
                      <div className="mb-4">
                        <label
                          htmlFor="bloqueadoPor"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Bloqueado Por</span>

                          <Multiselect
                            name="bloqueadoPor"
                            id="bloqueadoPor"
                            selectionLimit="8"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            closeOnSelect="true"
                            placeholder="Bloqueado por.."
                            options={opcionesBloqueadoPor} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            onSelect={handleBloqueadoPorChange}
                            onRemove={handleBloqueadoPorChange}
                            selectedValues={bloqueadoPor}
                          />
                        </label>
                      </div>

                      {/* Tiempo de finalizacion */}
                      <div className="grid gap-4 mb-4 mt-6 sm:grid-cols-4">
                        <label
                          htmlFor="finalizacion"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Fecha de Finalizacion </span>
                          <input
                            type="date"
                            id="finalizacion"
                            name="finalizacion"
                            className="inline p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.fechaEsperada}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>

                        <label
                          htmlFor="fechaCreacion"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Fecha de Creacion </span>
                          <input
                            type="text"
                            id="fechaCreacion"
                            name="fechaCreacion"
                            className="inline p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.fechaCreacion}
                            disabled={true}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>

                        <label
                          htmlFor="tiempoEstimado"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Tiempo Estimado (horas)</span>
                          <input
                            type="number"
                            id="tiempoEstimado"
                            name="tiempoEstimado"
                            className="inline p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.tiempoEstimado}
                            onChange={(e) =>
                              handleTiemposChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>

                        <label
                          htmlFor="tiempoTrabajado"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Tiempo Trabajado (horas)</span>
                          <input
                            type="number"
                            id="tiempoTrabajado"
                            name="tiempoTrabajado"
                            className="inline mb-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.tiempoTrabajado}
                            onChange={(e) =>
                              handleTiemposChange(e.target.name, e.target.value)
                            }
                            required
                          />
                          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <AccessAlarm />
                            <Slider
                              sx={{ cursor: 'null' }}
                              aria-label="Volume"
                              min={0}
                              max={parseInt(ticket.tiempoEstimado)}
                              value={parseInt(ticket.tiempoTrabajado)} />
                          </Stack>

                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                              variant="body2"
                              sx={{ cursor: 'null' }}
                            >
                              {parseInt(ticket.tiempoTrabajado)} horas <br /> trabajadas
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ cursor: 'null' }}
                            >
                              {parseInt(ticket.tiempoEstimado) - parseInt(ticket.tiempoTrabajado)} horas <br />restantes
                            </Typography>
                          </Box>

                        </label>
                      </div>

                    </div>

                    {/* Botones */}
                    <div className="mb-12 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        onClick={editTicket}
                      >
                        Guardar
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => addAssetModalSetting()}
                        ref={cancelButtonRef}
                      >
                        Cancelar
                      </button>
                    </div>

                    {showBloqueadoPorError && <CompletarCampoModal showModal={showBloqueadoPorError} setShowBloqueadoPorError={setShowBloqueadoPorError} nombre_campo="Bloqueado Por" />}
                    {showComentarioPendiente && <AgregarComentarioModal showModal={showComentarioPendiente} razonAPendiente={razonAPendiente} setShowComentarioPendiente={setShowComentarioPendiente} />}
                    {showModalSolucionado && <AgregarSolucionModal showModal={showModalSolucionado} completoSolucionado={completoSolucionado} setShowModalSolucionado={setShowModalSolucionado} />}

                    {/* TABS */}
                    <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                        {Object.keys(categories).map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                  ? 'bg-white text-blue-700 shadow'
                                  : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="py-2">
                        {Object.keys(categories).map((category, idx) => (
                          <Tab.Panel
                            key={idx}
                            className={classNames(
                              'rounded-xl bg-white-500 p-3',
                              'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                          >
                            {category == "Comentarios" && <CommentsComponent ticket_id={ticket.id_generado} comentarios={comentarios} getLogginInfo={getLogginInfo} execute={execute} />}
                            {category == "Historial" && ticketHistory && <HistoryTable data={ticketHistory}/>}
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                    {/* TABS */}

                  </form>

                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>

    </Transition.Root>
  )
}
